const takeScreenshotWithImageCapture = async function (stream) {
  const track = stream.getVideoTracks()[0];
  const imageCapture = new ImageCapture(track);
  const bitmap = await imageCapture.grabFrame();
  track.stop();
  const canvas = document.createElement('canvas');
  canvas.width = bitmap.width;
  canvas.height = bitmap.height;
  const context = canvas.getContext('2d');
  context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
  return canvas;
};

const takeScreenshotWithVideoPlay = function (stream) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    video.srcObject = stream;
    video.addEventListener('loadeddata', async () => {
      const { videoWidth, videoHeight } = video;
      canvas.width = videoWidth;
      canvas.height = videoHeight;
      await video.play();
      context.drawImage(video, 0, 0, videoWidth, videoHeight);
      stream.getVideoTracks()[0].stop();
      resolve(canvas);
    });
    video.addEventListener('onerror', function () {
      reject(video.error);
    });
  });
};

export default {
  takeScreenshotWithImageCapture,
  takeScreenshotWithVideoPlay,
};
