export default {
  "card": "_card_gw0oxz",
  "card-info": "_card-info_gw0oxz",
  "module-screenshot": "_module-screenshot_gw0oxz",
  "module-description-placeholder": "_module-description-placeholder_gw0oxz",
  "module-description-container": "_module-description-container_gw0oxz",
  "fade-in-loader": "_fade-in-loader_gw0oxz",
  "fade-in": "_fade-in_gw0oxz",
  "min-height-four-lines": "_min-height-four-lines_gw0oxz",
  "truncate-after-four-lines": "_truncate-after-four-lines_gw0oxz"
};
