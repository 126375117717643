import DS from 'ember-data';
import { inject as service } from '@ember/service';

import config from '../config/environment';

export default class Application extends DS.JSONAPIAdapter.extend({
  // anything which *must* be merged on the prototype
}) {
  // normal class body
  @service session;

  namespace = config.api.namespace;
  host = config.api.host;

  get headers() {
    let accessToken = this.session.ownAccessToken;

    return {
      Authorization: `Bearer ${accessToken}`,
      'Cache-Control': 'no-cache',
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    application: Application;
  }
}
