import Service from '@ember/service';
import localStorage from 'ember-local-storage-decorator';

export default class UrlStateService extends Service {
  @localStorage() deviceState;

  deviceStateFor(ccId) {
    return this.deviceState && this.deviceState[ccId];
  }

  updateDeviceStatesPerCC(ccId, value) {
    const newState = Object.assign({}, this.deviceState);
    // The argument MUST be either a string representing the state or null to
    // reset the state.
    if (value === null) {
      delete newState[ccId];
    } else {
      newState[ccId] = value;
    }

    this.deviceState = newState;
  }
}
