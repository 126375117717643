//Abstract class for defining interface
export default class IdentityProvider {
  login() {
    // eslint-disable-next-line
    console.log('Abstract login');
  }

  logout() {
    // eslint-disable-next-line
    console.log('Abstract logout');
  }

  update() {
    // eslint-disable-next-line
    console.log('Abstract update');
  }

  pickupSessionState() {
    // eslint-disable-next-line
    console.log('Abstract pickupSessionState');
  }

  ensureAccessTokenFor() {
    // eslint-disable-next-line
    console.log('Abstract ensureAccessTokenFor');
  }
}
