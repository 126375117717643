import OktaIdentityProvider from 'client-app-omnivise-web/utils/identity-providers/okta-idp';
import config from 'client-app-omnivise-web/config/environment';

export default class AzureFederatedIdentityProvider extends OktaIdentityProvider {
  get azureAdUrl() {
    return config.authConfig.azureAdUrl;
  }

  login() {
    window.location.href = this.azureAdUrl;
  }
}
