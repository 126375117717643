import Service from '@ember/service';
import { TrackedMap } from 'tracked-built-ins';

export default class UrlStateService extends Service {
  #urlStatesPerView = new TrackedMap();

  urlStateFor(view) {
    return this.#urlStatesPerView.get(view);
  }

  updateUrlStatesPerView(transition) {
    const { name, params, queryParams } = transition.to;

    if (name !== 'auth.view') {
      return;
    }
    if (queryParams.state) {
      this.#urlStatesPerView.set(params.view_id, JSON.parse(queryParams.state));
    } else {
      this.#urlStatesPerView.delete(params.view_id);
    }
  }
}
