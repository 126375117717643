import AzureIdentityProvider from 'client-app-omnivise-web/utils/identity-providers/azure-idp';
import OktaIdentityProvider from 'client-app-omnivise-web/utils/identity-providers/okta-idp';
import KeycloakIdentityProvider from 'client-app-omnivise-web/utils/identity-providers/keycloak-idp';
import AzureFederatedIdentityProvider from 'client-app-omnivise-web/utils/identity-providers/azure-federated-idp';
import TestIdentityProvider from 'client-app-omnivise-web/utils/identity-providers/test-idp';
import IdentityProviderFacade from 'client-app-omnivise-web/utils/identity-providers/idp-facade';

export default class IdentityProviderFactory {
  static createIdp(idpName) {
    switch (idpName) {
      case 'azure':
        return new AzureIdentityProvider();
      case 'keycloak':
        return new KeycloakIdentityProvider();
      case 'okta':
        return new OktaIdentityProvider();
      case 'azurefederated':
        return new AzureFederatedIdentityProvider();
      case 'testIdp':
      default:
        return new TestIdentityProvider();
    }
  }

  static createFacade(idpName) {
    return new IdentityProviderFacade(idpName);
  }
}
