export default {
  "required": "_required_ribypp",
  "form-element": "_form-element_ribypp",
  "form-wrapper": "_form-wrapper_ribypp",
  "home-add-ticket": "_home-add-ticket_ribypp",
  "spinner-wrapper": "_spinner-wrapper_ribypp",
  "action-buttons": "_action-buttons_ribypp",
  "success-message": "_success-message_ribypp",
  "attachments-wrapper": "_attachments-wrapper_ribypp",
  "attachments-wrapper-error": "_attachments-wrapper-error_ribypp",
  "drop-to-upload": "_drop-to-upload_ribypp",
  "file-list": "_file-list_ribypp",
  "dropzone": "_dropzone_ribypp",
  "file-upload": "_file-upload_ribypp",
  "attachments-footer": "_attachments-footer_ribypp"
};
