import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ShowTooltipService extends Service {
  @tracked shouldShowTooltip = false;

  setShouldShowTooltip(value) {
    this.shouldShowTooltip = value;
  }
}
