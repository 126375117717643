import * as Sentry from '@sentry/browser';

const logError = function (error) {
  Sentry.captureMessage(error);

  /* eslint-disable no-console */
  console.error(error);
};

const logRaiseError = function (error, context) {
  Sentry.captureException(error, (scope) => {
    scope.setTag('componentId', context.componentId);
    scope.setContext('state', { type: 'owc', value: context });
  });

  /* eslint-disable no-console */
  console.error({ error, context });
};

export default {
  logError,
  logRaiseError,
};
