import { helper } from '@ember/component/helper';

export function jsonStringify([value]) {
  if (value === null || value === undefined) {
    return null;
  }

  return JSON.stringify(value);
}

export default helper(jsonStringify);
