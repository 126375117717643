import Model, { attr } from '@ember-data/model';

export default class extends Model {
  @attr('string') title;
  @attr('string') description;
  @attr('string') category;
  @attr({ defaultValue: () => [] }) assets;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('date') publishedAt;
}
